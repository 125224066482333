import { EnvironmentProviders, makeEnvironmentProviders } from "@angular/core";
import { AnalyticsService } from "./services/analytics.service";
import { ProvideAnalyticsFeature } from "./base/provide-analytics-feature";



export function provideCueAnalytics(
  ...features: ProvideAnalyticsFeature[]
) : EnvironmentProviders{
    return makeEnvironmentProviders([
       AnalyticsService,
      features.map((feature) => feature.providers),
    ]);
}
