import { ProvideAnalyticsFeature } from "../base/provide-analytics-feature";
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { AnalyticsService } from "../services/analytics.service";


type AnalyticsInterceptorOptions = {
  apiDomains: string[],
  sessionHeaderName: string,
  deviceIDHeaderName: string
};

const ANALYTICS_INTERCEPTOR_OPTIONS = new InjectionToken<AnalyticsInterceptorOptions>("ANALYTICS_INTERCEPTOR_OPTIONS");

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class AnalyticsInterceptor implements HttpInterceptor {

  analyticsService = inject(AnalyticsService);
  options = inject(ANALYTICS_INTERCEPTOR_OPTIONS);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();
    const isApi = this.options.apiDomains.some(domain => url.startsWith(domain));
    if (isApi) {
      const sessionID = this.analyticsService.sessionId;
      const deviceID = this.analyticsService.deviceId;
      const headers = req.headers
        .set(this.options.sessionHeaderName, sessionID ?? "")
        .set(this.options.deviceIDHeaderName, deviceID ?? "");
      const clone = req.clone({ headers });
      return next.handle(clone);
    }
    return next.handle(req);
  }

}

export function withHeadersIntecceptor(options: {
  apiDomains: () => string[],
  sessionHeaderName: string,
  deviceIDHeaderName: string
}): ProvideAnalyticsFeature {
  return {
    type: "withHeadersIntecceptor",
    providers: [
      {
        provide: ANALYTICS_INTERCEPTOR_OPTIONS,
        useFactory: () => {
          return {
            apiDomains: options.apiDomains(),
            sessionHeaderName: options.sessionHeaderName,
            deviceIDHeaderName: options.deviceIDHeaderName
          };
        }
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AnalyticsInterceptor,
        multi: true
      }
    ]
  };
}
