import { Pipe, PipeTransform } from '@angular/core';
import {translation}                     from '@assist/shared/translations';
import { ConfigService }                 from '@assist/shared/data';
import { AssistResourceBM }              from '@cue/api';

@Pipe({
  name: 'resourceTitle',
  standalone: true
})
export class ResourceTitlePipe implements PipeTransform {
  translation = translation;
  constructor(
    private configService: ConfigService,
    ) {}

  transform(value: AssistResourceBM): string {
    return `${value.displayNameForApp ?? value.displayName}
      (${this.configService.getResourceTypeFromId(value.resourceTypeId).name})`;
  }
}
