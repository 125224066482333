import { Pipe, PipeTransform, inject } from "@angular/core";
import {DatePipe} from '@angular/common';
import { PipesService } from "./pipes.service";

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {
  private readonly pipesService =  inject(PipesService);
  private readonly datePipe = inject(DatePipe);

  transform(value: string | number | Date): unknown {
    return this.datePipe.transform(value, this.pipesService.dateFormat);
  }
}
