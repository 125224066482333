import { inject, Injectable, LOCALE_ID } from "@angular/core";
import { FormatWidth, getLocaleDateFormat } from "@angular/common";

@Injectable()
export class PipesService {

  private readonly locale = inject(LOCALE_ID);

  private _timeFormat = 'HH:mm';
  private _dateFormat =  getLocaleDateFormat(this.locale, FormatWidth.Short);
  get dateTimeFormat(): string {
    return this._dateFormat + ' ' + this._timeFormat;
  }

  get dateFormat(): string {
    return this._dateFormat;
  }

  get timeFormat(): string {
    return this._timeFormat;
  }



  set dateFormat(value: string) {
    this._dateFormat = value;
  }

  set timeFormat(value: string) {
    this._timeFormat = value;
  }
}
